import React from 'react';

import './assets/scss/themes.scss';

import Route from './Routes';

import { ToastContainer } from 'react-toastify';
import { Switch } from 'react-router-dom';
import ReactGA from 'react-ga4';



function App() {
  ReactGA.initialize('G-P7N889SGDD');

  return (
    <React.Fragment>

      <Switch>
        <Route />
      </Switch>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
