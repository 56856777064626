import React, { useEffect, useState } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';

import { URL } from '../../../constants';

import axios from 'axios';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ProjectCountryMap from '../../Charts/components/Project-Country-Map';

// There should be 17 of these.
// I've not imported them all beacause I'm not sure that is the right way to do it
import sdg1 from '../../../assets/images/sdg/E-WEB-Goal-01.png';
import sdg2 from '../../../assets/images/sdg/E-WEB-Goal-02.png';
import sdg3 from '../../../assets/images/sdg/E-WEB-Goal-03.png';
import sdg4 from '../../../assets/images/sdg/E-WEB-Goal-04.png';
import sdg5 from '../../../assets/images/sdg/E-WEB-Goal-05.png';
import sdg6 from '../../../assets/images/sdg/E-WEB-Goal-06.png';
import sdg7 from '../../../assets/images/sdg/E-WEB-Goal-07.png';
import sdg8 from '../../../assets/images/sdg/E-WEB-Goal-08.png';
import sdg9 from '../../../assets/images/sdg/E-WEB-Goal-09.png';
import sdg10 from '../../../assets/images/sdg/E-WEB-Goal-10.png';
import sdg11 from '../../../assets/images/sdg/E-WEB-Goal-11.png';
import sdg12 from '../../../assets/images/sdg/E-WEB-Goal-12.png';
import sdg13 from '../../../assets/images/sdg/E-WEB-Goal-13.png';
import sdg14 from '../../../assets/images/sdg/E-WEB-Goal-14.png';
import sdg15 from '../../../assets/images/sdg/E-WEB-Goal-15.png';
import sdg16 from '../../../assets/images/sdg/E-WEB-Goal-16.png';
import sdg17 from '../../../assets/images/sdg/E-WEB-Goal-17.png';
//import { VectorMap } from 'react-jvectormap';


//import SwiperCore, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Link, useHistory, useParams } from 'react-router-dom';


import ReactGA from 'react-ga4';

ReactGA.initialize('G-P7N889SGDD');

//SwiperCore.use([FreeMode, Navigation, Thumbs]);

const SDGImages = {
  1: sdg1,
  2: sdg2,
  3: sdg3,
  4: sdg4,
  5: sdg5,
  6: sdg6,
  7: sdg7,
  8: sdg8,
  9: sdg9,
  10: sdg10,
  11: sdg11,
  12: sdg12,
  13: sdg13,
  14: sdg14,
  15: sdg15,
  16: sdg16,
  17: sdg17,
};

const SDGNames = {
  1: 'No Poverty',
  2: 'Zero Hunger',
  3: 'Good Health and Well-being',
  4: 'Quality Education',
  5: 'Gender Equality',
  6: 'Clean Water and Sanitation',
  7: 'Affordable and Clean Energy',
  8: 'Decent Work and Economic Growth',
  9: 'Industry, Innovation and Infrastructure',
  10: 'Reduced Inequality',
  11: 'Sustainable Cities and Communities',
  12: 'Responsible Consumption and Production',
  13: 'Climate Action',
  14: 'Life Below Water',
  15: 'Life on Land',
  16: 'Peace and Justice Strong Institutions',
  17: 'Partnerships to achieve the Goal',
};




// const Vectormap = (props) => {
//   const map = React.createRef(null);
//   return (
//     <>
//       <div style={{ width: props?.width, height: 300 }}>
//         <VectorMap
//           map={props?.value}
//           backgroundColor='transparent'
//           ref={map}
//           containerStyle={{
//             width: '100%',
//             height: '70%',
//           }}
//           regionStyle={{
//             initial: {
//               // fill: props.color,
//               fill: '#AFC19888',
//               stroke: 'transparent',
//               'stroke-width': 0,
//               'stroke-opacity': 0,
//             },
//             hover: {
//               'fill-opacity': 0.5,
//               cursor: 'pointer',
//             },
//             selected: {
//               fill: '#3a3b8b', //what colour clicked country will be
//             },
//             selectedHover: {},
//           }}
//           series={{
//             regions: [
//               {
//                 values: { [props?.country]: 100000 }, //this is your data
//                 scale: ['#ff0000', '#3a3b8b'], //your color game's here
//                 normalizeFunction: 'polynomial',
//               },
//             ],
//           }}
//           containerClassName='map'
//         />
//       </div>
//     </>
//   );
// };

function ProjectDetail(props) {
  const history = useHistory();
  const product_id = props.product_id;

  

  
  const [dataReady, setDataReady] = useState(false);
  const [catColor, setCatColor] = useState('#d4d7d4');
  const [creditsIssued, setCreditsIssued] = useState(0);
  const [creditsRetired, setCreditsRetired] = useState(0);
  const [creditsRemaining, setCreditsRemaining] = useState(0);
  const [circleChartOptions, setCircleChartOptions] = useState({});
  const [retiredPercentage, setRetiredPercentage] = useState(0);
  const [remainingPercentage, setRemainingPercentage] = useState(0);
  const [standard, setStandard] = useState('');
  const [projectName, setProjectName] = useState('');
  const [firstYearOfProject, setFirstYearOfProject] = useState('');
  const [projectOwner, setProjectOwner] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [catName, setCatName] = useState('');
  const [typeName, setTypeName] = useState('');
  const [reductionRemoval, setReductionRemoval] = useState('');
  const [methodology, setMethodology] = useState('');
  const [projectSDGs, setProjectSDGs] = useState([]);
  const [projectCountry, setProjectCountry] = useState('');
  const [projectCountryCode, setProjectCountryCode] = useState('');
  const [registryDocuments, setRegistryDocuments] = useState('');
  const [projectWebsite, setProjectWebsite] = useState('');



  const numberWithCommas = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  

  useEffect(() => {
    loadProjectData();
  }, []); 




const loadProjectData = () => {
  axios.get(URL.get_project_pages+`/?project_id=${product_id}`).then((response) => { 
    if( response.data.length ){
      setCatColor(response.data[0].color);
      setCreditsIssued(response.data[0].total_credits_issued);
      setCreditsRetired(response.data[0].total_credits_retired);
      setCreditsRemaining(response.data[0].total_credits_remaining);  
      setStandard(response.data[0].project_standard);
      setProjectName(response.data[0].project_name);
      setFirstYearOfProject(response.data[0].first_year_of_project);
      setProjectOwner(response.data[0].project_owner);
      setProjectCode(response.data[0].project_code);
      setCatName(response.data[0].categoryname);
      setTypeName(response.data[0].typename);
      setReductionRemoval(response.data[0].rdxn_rmvl);
      setMethodology(response.data[0].methdology); 
      setProjectSDGs(response.data[0].project_sdgs ? response.data[0].project_sdgs.split(','): []);
      setProjectCountry(response.data[0].project_country);
      setProjectCountryCode(response.data[0].project_country_iso2);
      setRegistryDocuments(response.data[0].registry_documents);
      setProjectWebsite(response.data[0].project_website);
      if( ! response.data[0].total_credits_issued ){
        setDataReady(true);
      }
    }
  });
};
  
useEffect(() => {
  setRemainingPercentage(100 - retiredPercentage);
}, [retiredPercentage]);

useEffect(() => {
  if( creditsIssued && creditsIssued != 0 ){
    setRetiredPercentage((creditsRetired / creditsIssued * 100).toFixed(0));
  } 
}, [creditsIssued, creditsRetired]);

useEffect(() => {
  if( creditsIssued != 0 && (creditsRetired != 0 || creditsRemaining != 0 )){
    setCircleChartOptions( {
      chart: {
          plotBackgroundColor: 'transparent',
          plotBorderWidth: 0,
          plotShadow: false,
          height: 140,
          width: 400,  
      },
      title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: 60,
          style: {
              fontSize: '1.1em'
          }
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          pie: {
              animation:false,
              dataLabels: {
                enabled: false,
                distance: -50,
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
              },
              colors: ['#6162bd', '#3a3b8b'],
              
              startAngle: -90,
              endAngle: 90,
              center: ['50%', '75%'],
              size: '250%'
          }
      },
      series: [{
          type: 'pie',
          name: '',
          innerSize: '50%',
          data: [
            ['Retired', creditsRetired],
            ['Remaining', creditsRemaining],
              
          ]
      }]
    });
  }
  setDataReady(true);
}, [creditsIssued, creditsRetired, creditsRemaining]);

useEffect(() => {
  if( dataReady ){
    ReactGA.send({ hitType: "pageview", page: "/projectDetail/"+product_id, title: "Public Project Detail Page: "+projectName });
  }
}, [dataReady]);


  document.title = projectName;
  // add Project Name here
  return ( dataReady && (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Project Details'
          pageTitle='Projects'
          pageTitleLink='/projects'
        />

        <Row>
          <Col lg={12}>
            <Card className=''>
              <div
                className=''
                style={{
                  borderColor:
                    (catColor) ||
                    '#d4d7d4',
                  borderRadius: '5px',
                  borderWidth: '0 0 0 10px',
                  borderStyle: 'solid',
                  backgroundOpacity: '0.3',
                }}
              >
                <CardBody className='pb-0 px-4'>
                  <Row className='mb-3'>
                    <div className='col-md'>
                      <Row className='align-items-center g-3'>
                        <div className='col-md pb-2'>
                          <div>
                            <h1 className='fw-bold mt-1 mb-3'>
                              {projectName || '-'} 
                            </h1>

                            <div className='hstack gap-3 flex-wrap'>
                              <div>
                                <i className='ri-building-line align-bottom me-1'></i>
                                {standard || '-'}
                              </div>
                              <div className='vr'></div>
                              <span className=''>{projectCode}</span>
                              
                              
                              {firstYearOfProject && (<><div className='vr'></div><div>
                                First Year :{' '}
                                <span className='fw-medium'>
                                  {firstYearOfProject || '-'}
                                </span>
                              </div></>)}
                             
                              {projectOwner} 
                              
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                    
                  </Row>
                </CardBody>
                <div
                  className='text-white py-2'
                  style={{
                    backgroundColor:
                      (catColor) ||
                      '#d4d7d4',
                  }}
                >
                  {catName || 'No Category name'}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={9} lg={8}>
            <Card>
              <CardBody>
                <div className='text-muted'>
                  <div className='hstack gap-2'>
                    <div className='pe-4  '>
                      <p className='mb-2 text-uppercase fw-medium'>Type :</p>
                      <h5 className='x'>
                        {typeName || 'None'}
                      </h5>
                    </div>

                    <div className='pe-4 ms-auto'>
                      <p className='mb-2 text-uppercase fw-medium'>
                        Reduction / Removal :
                      </p>
                      <div className='badge bg-info fs-12'>
                        {reductionRemoval || 'None'}
                      </div>
                    </div>

                    <div className='ms-auto'>
                      <p className='mb-2 text-uppercase fw-medium'>
                        Methodology :
                      </p>
                      <div className='badge bg-light text-dark fs-12'>
                        {methodology || 'None'}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>


            { typeof creditsIssued === 'number' && creditsIssued > 0 ? (<Card className='bg-soft-info'>
              <CardBody>
                <div className='d-flex justify-content-between'>
                  <div>
                    <p className='fw-medium mb-0'>ISSUANCES REMAINING {/*({project?.berkeley_details?.issuances_remaining_parenthetical ? project?.berkeley_details?.issuances_remaining_parenthetical : 'October 2023'})*/}</p>
                    <h2 className='mt-2 ff-secondary fw-semibold display-6'>
                          { typeof creditsRemaining === 'number' ? numberWithCommas(creditsRemaining) : '-' }
                      {/* <span className='counter-value' data-target='3045938'>
                        { typeof project?.berkeley_details?.total_credits_remaining === 'number' ? (
                          <CountUp
                            start={0}
                            end={
                              project?.berkeley_details
                                ?.total_credits_remaining || '-'
                            }
                            duration={2}
                            separator=','
                          />
                        ) : (
                          '-'
                        )}
                      </span> */}
                      <span className='text-muted fs-6'> TCO2e </span>
                    </h2>

                    <div className='hstack gap-3'>
                      <div className='mb-1 text-muted'>
                        <span className='badge bg-light text-primary mb-0 fs-12' ref={(node) => {
      if (node) {
        node.style.setProperty("color", "#3a3b8b", "important");
      }
    }}>
                          <i className='ri-arrow-right-line align-middle'></i>
                          {creditsIssued
                            ? parseInt(
                                creditsIssued
                              )?.toLocaleString()
                            : '-'}{' '}
                        </span>{' '}
                        &nbsp; issued
                      </div>

                      <div className='mb-1 text-muted me-auto'>
                        <span className='badge bg-light text-danger mb-0 fs-12' ref={(node2) => {
      if (node2) {
        node2.style.setProperty("color", "#6162bd", "important");
      }
    }}>
                          <i className='ri-arrow-down-line align-middle'></i>
                          {creditsRetired
                            ? parseInt(
                                creditsRetired
                              )?.toLocaleString()
                            : '-'}
                        </span>{' '}
                        retired
                      </div>

                      {/* <div className='mb-1 text-muted position-absolute end-0 me-3'>
                        <span className='badge bg-light text-dark mb-0 fs-12'>
                          <i className='ri-arrow-down-line align-middle'></i>
                          {parseInt(
                            project?.berkeley_details
                              ?.estimated_annual_emission_reductions
                          )?.toLocaleString() || '-'}
                        </span>{' '}
                        est. annual rate
                      </div> */}
                      
                        
                      
                      <div className='mb-1 text-muted position-absolute end-0 me-3' style={{top:0}}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={circleChartOptions}
                        />
                        
                          <div style={{textAlign:'center', lineHeight:'24px',position:'absolute',bottom:'10px', left:'10px', color: 'rgb(97, 98, 189)'}}><span style={{fontSize:'30px'}}>{retiredPercentage}%</span><br />retired</div>
                        
                        
                        <div style={{textAlign:'center', lineHeight:'24px',position:'absolute',bottom:'10px', right:'10px', color: 'rgb(97, 98, 189)'}}><span style={{fontSize:'30px'}}>{remainingPercentage}%</span><br />remaining</div>
                         
                      </div> 

                      <style dangerouslySetInnerHTML={{__html: `
                          .highcharts-credits, .highcharts-background { display:none } 
                          .highcharts-plot-background {
                            x: 0;
                            y: 0;
                            width: 300px;
                            height: 140px;  
                          }
                          .highcharts-series-group {
                            transform: translate(0, 50px);
                          }    
                            .highcharts-data-labels {
                              display: none;
                            } 
                      `}} />
                    </div>
                  </div>

                  
                </div>
              </CardBody>
             
            </Card>) : (
              <Card className='bg-soft-info'>
                <CardBody>
                  <div className='d-flex justify-content-between'>
                Issuance and retirement data not available for this project
                  </div>
                </CardBody>
              </Card>
            )}
            

            <Card>
              <CardHeader>
                <h5 className='card-title'>Sustainable Development Goals</h5>
              </CardHeader>
              <CardBody>
                <div className=''>
                  {projectSDGs?.length > 0 ? (
                    projectSDGs?.map((sdgcode) => {
                      return (
                        sdgcode && (
                          <span key={`sdg${sdgcode}`}>
                            <img
                              src={SDGImages[sdgcode]}
                              className='rounded avatar-lg me-1 mb-1'
                              id={`sdg${sdgcode}`}
                              alt='SDG Name'
                            />
                            <UncontrolledTooltip
                              placement='top'
                              target={`sdg${sdgcode}`} 
                            >
                              {SDGNames[sdgcode]}
                            </UncontrolledTooltip>
                          </span>
                        )
                      );
                    })
                  ) : (
                    <p>
                      This project has not been assessed for Sustainable
                      Development Goals.
                    </p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3} lg={4}>
            <Card>
              <CardBody>
                <h5 className='card-title mb-4'>
                  Location: {projectCountry} 
                </h5>
                <div id='highcharts-vectormap' style={{ height: '200px' }}>
                  {/* <Vectormap
                    value='world_mill'
                    width='724'
                    color='#695eef'
                    country={projectCountryCode || ''} 
                  /> */}
                  {dataReady && projectCountry !== '' && projectCountryCode !== '' && <ProjectCountryMap CountryCode={projectCountryCode} CountryName={projectCountry} />}
                </div>
              </CardBody>
            </Card>


            {(registryDocuments || projectWebsite) && (<Card>
              <CardHeader className='align-items-center d-flex border-bottom-dashed'>
                <h5 className='card-title mb-0 flex-grow-1'>Links</h5>
              </CardHeader>

              <CardBody>
                <div className='vstack gap-2'>
                  { registryDocuments && (<div className='border rounded border-dashed p-2'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <div className='avatar-sm'>
                          <div
                            className={`avatar-title bg-light ${
                              registryDocuments
                                ? 'text-secondary'
                                : ''
                            } rounded fs-24`}
                          >
                            <i className='ri-folder-zip-line'></i>
                          </div>
                        </div>
                      </div>
                      <div className='flex-grow-1 overflow-hidden'>
                        <h5 className='fs-13 mb-1'>
                          
                            <a
                              href={
                                registryDocuments ||
                                '#'
                              }
                              target='_blank'
                              rel='noreferrer'
                              className={`${
                                registryDocuments
                                  ? 'text-secondary'
                                  : 'text-body'
                              } text-truncate d-block`}
                            >
                              Registry
                            </a>
                          
                        </h5>
                      </div>
                    </div>
                  </div>)}
                  {projectWebsite && (<div className='border rounded border-dashed p-2'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <div className='avatar-sm'>
                          <div
                            className={`avatar-title bg-light ${
                              projectWebsite
                                ? 'text-secondary'
                                : ''
                            } rounded fs-24`}
                          >
                            <i className='ri-link'></i>
                          </div>
                        </div>
                      </div>
                      <div className='flex-grow-1 overflow-hidden'>
                        <h5 className='fs-13 mb-1'>
                          {projectWebsite ? (
                            <a
                              href={
                                projectWebsite || ''
                              }
                              target='_blank'
                              rel='noreferrer'
                              className={`${
                                projectWebsite
                                  ? 'text-secondary'
                                  : 'text-body'
                              } text-truncate d-block`}
                            >
                              Project Website
                            </a>
                          ) : (
                            <a
                              className={`${'text-body'} text-truncate d-block`}
                            >
                              Project Website
                            </a>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>)}
                </div>
              </CardBody>
            </Card>)}
          </Col>
        </Row>
      </Container> 
    </div>
  ));
}

export default ProjectDetail;
