import React, { useEffect, useState } from 'react';
import {
    Col,
    Container,
    CardHeader,
    Button, 
    Row,
    Card,
    CardBody,
    Spinner,
  } from 'reactstrap';
  import CountUp from 'react-countup';
  

  import axios from 'axios';
  import { api } from '../../../config';
  import { useSelector } from 'react-redux';


  import RespondentVolumeByCountryMap from '../../Charts/components/RespondentVolumeByCountryMap';
  import RespondentVolumeBarChart from '../../Charts/components/RespondentVolume-BarChart';
  import RespondentsVolumeByCategory from '../../Charts/components/RespondentVolumeByCategory-StackedBarChart';
  import RespondentsValueUSDByCategory from '../../Charts/components/RespondentValueUSDByCategory-DonutChart';







const MyData = () => {

    const [dashboardData, setDashboardData] = useState();
    const [timetable, setTimeTable] = useState();

    

    const { user } = useSelector((state) => ({
        user: state.Login.user,
    }));

    const respondent_name = user?.respondent_name;
    const getDashboardData = async (respondent_id) => {
        if( respondent_id ){
        try {
            const response = await axios.post(
            `${api?.API_URL}/api/respondent-dashboard/`,
            { respondent_id }
            );
            setDashboardData(response);
        } catch (error) {
            console.log(error);
        }
        }
    };

    const getDate = () => {
        let date = new Date(
        dashboardData?.recent_transactions_date
        ).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        let d = new Date(
        dashboardData?.recent_transactions_date
        ).toLocaleDateString('en-US', { day: 'numeric' });
        let compeleteDate = { day: d, monthyear: date };
        setTimeTable(compeleteDate);
    };

    useEffect(() => {
        getDate();
    }, [dashboardData]);
    
    useEffect(() => {
        getDashboardData(user?.respondent_id);
    }, []);

    

    document.title = 'Dashboard | Ecosystem Marketplace';
    let isViewer = false;
    if( user?.role == 3 ){
        isViewer = true;
    }
    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
              {/* <Row className='mt-3 mb-2'>
                <Col sm={12}>
                  <h1 className='fw-bold'>My data</h1>
                </Col>
              </Row> */}
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
            { !dashboardData && <Spinner  />}
            { dashboardData &&  (
            <>
              
              {' '}
              <Row>
                {/* <Col sm={3} style={{position: 'fixed'}}>
                  <h1 className='fw-bold'>{respondent_name} Data</h1>
                  
                </Col> */}
                <Col sm={3} style={{position: 'fixed'}}>
                  <h1 className='fw-bold'>{respondent_name} Data</h1>
                  <RespondentsValueUSDByCategory />
                
                </Col>
                <Col sm={8} style={{marginLeft: '30%', marginTop: '40px'}}>
                {/*<h1 className='fw-bold'>{respondent_name} Data</h1>
                <Col sm={12} style={{marginLeft: '0', marginTop: '40px'}}>*/}
                  <Row>
                    {/* <Widgets /> */}
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-money-dollar-circle-fill'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Total USD Value
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_value_sum}
                                  decimals={0}
                                  separator=','
                                  prefix='$'
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-price-tag-2-line'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Avg USD/TCO2e
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_average_price_usd}
                                  decimals={2}
                                  separator=','
                                  prefix='$'
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle  ri-cloud-fill'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Total Volume TCO2e
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_volume_sum}
                                  decimals={0}
                                  separator=','
                                  prefix=''
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-arrow-left-right-line'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Total Number of Trades
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_count}
                                  // decimals={2}
                                  separator=','
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-leaf-fill'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Total Projects Traded
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.unique_projects_traded}
                                  // decimals={2}
                                  separator=','
                                  duration={1}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-calendar-event-line'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Most recent trade
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                {/* {dashboardData?.recent_transactions_date} */}
                                {`${timetable?.day} ${timetable?.monthyear}`}
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* <MarketGraph /> */}
                  </Row>
                  <Row>
                    <Col>
                      <Card className=''>
                        <CardHeader>
                          <h5 className='card-title mb-0 flex-grow-1'>
                            Where are the Projects you've traded?
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <RespondentVolumeByCountryMap />
                          {/* <div
                            id='users-by-country'
                            style={{ height: '352px' }}>
                            <Vectormap
                              value='world_mill'
                              color='#eeeeee'
                              regions={dashboardData?.group_by_country?.map(
                                (country) => {
                                  return {
                                    values: {
                                      [country?.projectid__country__iso2
                                        ? country?.projectid__country__iso2
                                        : country?.projectid__country__iso3]: 100000,
                                    }, //this is your data
                                    scale: ['#146804', '#DC6B2F'], //your color game's here
                                    normalizeFunction: 'polynomial',
                                  };
                                }
                              )}
                            />
                          </div> */}
                        </CardBody>
                      </Card>
                      {/* <Card className='mt-3'>
                        <CardBody>
                          <DatesBarChart dashboardData={dashboardData} />
                        </CardBody>
                      </Card>
                      <Card className='mt-3'>
                        <CardBody>
                          <DashboardLineBarChart
                            dashboardData={dashboardData}
                          />
                        </CardBody>
                      </Card> */}
                    </Col>
                  </Row>

                  <Row>
                    <RespondentVolumeBarChart />
                  </Row>

                  <Row>
                    <RespondentsVolumeByCategory />
                  </Row>




                </Col>
              </Row>
              <Row>{/* <Widgets1 /> */}</Row>
              
            </>
          )}
            </Container>
        </div>
        </React.Fragment>
    );
};

export default MyData;
